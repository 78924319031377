import Swiper, { Navigation, Pagination, Autoplay } from 'swiper';
import 'swiper/swiper.min.css';
import 'swiper/modules/navigation/navigation.min.css';
import 'swiper/modules/pagination/pagination.min.css';

export default class LatestArticles {
    init(root) {
        this.root = root;
        //console.log(this.root)
        // this.next = root.querySelector('.swiper-button-next');
        // this.prev = root.querySelector('.swiper-button-prev');
        this.swiper = new Swiper(this.root, {
            slidesPerView: "auto",
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            },
            breakpoints: {
                0: {
                    spaceBetween: 16,
                },
                640: {
                    spaceBetween: 32,
                },
            },
            spaceBetween: 0,
            modules: [Pagination],
        });
        this.root.style.opacity = 1;
    }
    
    destroy() {
        this.swiper.destroy();
    }
}

import { loopback, map, clamp } from './../../js/utils/math.js';
import { doc, cursor, scrl, getPosition, inViewport, registeredLoops, spanFerkel, touchDevice} from './../../js/utils/events.js';

export default class LogoGrid {
    init(root) {
        this.root = root
        this.logos = []

        this.logos = this.root.querySelectorAll('.item')
        
        registeredLoops.push({ //add this.loop to global requestAnimationFrame
            root: this.root,
            component: this,
            position: getPosition(this.root), //trigger element
            loop: this.loop
        })
    }

    loop() {
        const inView = inViewport(this.position);
        const progress = map(inView.progress, 0.1, 0.3, 0.1, 1);

        
        // Define the stagger factor to determine how much each character is delayed.
        const staggerFactor = 0.1; // Adjust this value as needed.
    
        this.component.logos.forEach((el, i) => {
            // Calculate the progress offset for each character based on its index.
            const offset = i * staggerFactor;
            const logoProgress = clamp(progress - offset, 0.1, 1);
    
            // Set the opacity of each character based on the adjusted progress.
            el.style.opacity = logoProgress;
        });
    }
    
}

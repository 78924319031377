export default class Footer {
    init(root) {
        this.root = root;
        this.footer = this.root;
        this.footerContainer = this.root.querySelector('.footer-container');
        this.footerSpacer = document.querySelector('.footer-spacer');

        this.spaceTop = 80;

        this.updateMargin();
        window.addEventListener('resize', () => this.updateMargin());
    }

    updateMargin() {
        const footerHeight = this.footerContainer.offsetHeight;

        // console.log('Updating margin. Footer height:', footerHeight);

        // Reset to fixed position initially
        this.footer.style.position = "fixed";
        this.footerSpacer.style.height = `${window.innerHeight - this.spaceTop}px`;

        // Adjust position and height if the footer height exceeds the window height
        if (footerHeight > window.innerHeight - this.spaceTop) {
            this.footer.style.position = "relative";
            this.footerSpacer.style.height = `0px`;
        }
    }
}

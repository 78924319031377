import barba from '@barba/core';

export default class BackButton {
    init(root) {
        this.root = root;
        this.addHistoryLink();
    }

    addHistoryLink() {
        const referrer = document.referrer;
        if (referrer && new URL(referrer).origin === window.location.origin) { // Check if there is a referrer and if it's from the same domain
            this.root.addEventListener('click', (e) => {
                e.preventDefault();
                this.goBackWithBarba();
            });
        } else {
            // Use fallback from CMS or another default URL
            // this.root.addEventListener('click', (e) => {
            //     e.preventDefault();
            //     window.location.href = '/'; // Fallback to the homepage or another default URL
            // });
        }
    }

    goBackWithBarba() {
        const referrer = document.referrer;
        if (referrer && new URL(referrer).origin === window.location.origin) {
            barba.go(referrer, "back");
        } else {
            // Fallback if no valid referrer is found
            barba.go('/', "back");
        }
    }
}

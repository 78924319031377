import { gsap } from "gsap";

export default class TransitionOverlay {
    init(root) {
        this.root = root;
        this.itemsBase = [];
        this.render();
    }

    render() {
        //console.log(this.root);

        for (let i = 0; i < 200; i++) {
            const span = document.createElement('span');
            this.itemsBase.push(span);
            this.root.appendChild(span);
        }

        // this.show()
    }

    show() {
        // this.root.display = "grid";
        return gsap.fromTo(this.itemsBase, 
            { scaleX: 0 }, 
            { 
                scaleX: 1,
                duration: 0.08,
                ease: 'circ.out',
                stagger: {
                    each: 0.05,
                    from: 'center',
                    grid: [10, 20],
                    // repeat: -1
                },
                onStart: () => {
                    this.root.style.display = "grid";
                }
            }
        );
    }
    hide() {   
        return gsap.fromTo(this.itemsBase, 
            { scaleX: 1 }, 
            { 
                scaleX: 0,
                duration: 0.08,
                ease: 'circ.in',
                stagger: {
                    each: 0.05,
                    from: 'center',
                    grid: [10, 20],
                    // ease: 'power2.in'
                },
                onComplete: () => {
                    this.root.style.display = "none";
                }
            }
        );        
    }
}


import { loopback, map, clamp } from './../../js/utils/math.js';
import { doc, cursor, scrl, getPosition, inViewport, registeredLoops, registeredResizeFunctions,lenisScrollTo, stopLenis, startLenis, touchDevice} from './../../js/utils/events.js';

export default class Hero {
    init(root) {
        this.component = this
        this.root = root
        this.visual = this.root.querySelector('.hero-video, .hero-visual')
        this.text = this.root.querySelector('.hero-text')
        this.textBottom = this.root.querySelector('.text-bottom')
        this.render()
        // console.log('this.root')
        // console.log(this.root)

        this.hasMobileVideo = this.root.querySelector('.has-mobile-video')
        if(this.hasMobileVideo) {
            this.resizeHasMobileVideo()
            registeredResizeFunctions.push({ //add this.loop to global requestAnimationFrame
                root: this.root,
                component: this,
                resize: this.resizeHasMobileVideo
            })
        }

        if(this.root.classList.contains('hero--article')) {
            registeredLoops.push({ //add this.loop to global requestAnimationFrame
                root: this.root,
                component: this,
                position: getPosition(this.root), //trigger element
                loop: this.loopHeroArticle
            })
        } else {
            registeredLoops.push({ //add this.loop to global requestAnimationFrame
                root: this.root,
                component: this,
                position: getPosition(this.root), //trigger element
                loop: this.loop
            })
        }

        // this.root.style.marginBottom = "50svh";

        //console.log(registeredLoops)
    }

    resizeHasMobileVideo() {
        const videoSourceElement = this.component.hasMobileVideo.querySelector('source');
        const isLandscape = doc.w > doc.h;

        const mobileSrc = videoSourceElement.getAttribute('data-srcmobile');
        const desktopSrc = videoSourceElement.getAttribute('data-src');

        // Check orientation and set the appropriate src
        if (isLandscape && desktopSrc) {
            videoSourceElement.setAttribute('src', desktopSrc);
        } else if (mobileSrc) {
            videoSourceElement.setAttribute('src', mobileSrc);
        }

        // Reload the video
        this.component.hasMobileVideo.load();
        this.component.hasMobileVideo.play();
    }

    render() {
        //console.log(this.root)
    }

    loop(time) {
        const inView = inViewport(this.position)
        // console.log(this.root)
        // this.root.style.setProperty('--progress', `${inView.progress}`);
        // this.root.style.setProperty('--inview', `${inView.loopback}`);
        // this.root.style.transform = `translate3d(0, ${map(progress, 0.5, 1, 0, 100)}%, 0)`;
        const progress = clamp(inView.progress, 0, 1);
        // console.log(progress)
        this.component.visual.style.opacity = map(progress, 0.5, 0.75, 1, 0);
        this.component.visual.style.transform = `translate3d(0, 0, 0) scale(${map(progress, 0.5, 0.75, 1, 1.2)})`;
        // this.component.visual.style.transform = `translate3d(0, ${map(progress, 0.5, 1, 0, 100)}%, 0) scale(${map(progress, 0.5, 1, 1, 1.2)})`;
        // this.component.text.style.transform = `translate(-50%, -50%) translate3d(0, ${map(progress, 0.5, 1, 0, 90)}svh, 0)`;
        this.component.text.style.transform = `translate(-50%, -100%) translate3d(0, ${map(progress, 0.5, 0.75, 0, -10)}svh, 0)`;
        this.component.text.style.opacity = map(progress, 0.5, 0.75, 1, 0);
        // this.component.textBottom.style.transform = `translate(-50%, 0) translate3d(0, ${map(progress, 0.5, 1, 0, 100)}svh, 0)`;
        this.component.textBottom.style.opacity = map(progress, 0.5, 0.75, 1, 0);
        if(inView.loopback === 1) {
            // this.root.classList.add('fade-in')
            this.component.visual.style.pointerEvents = 'auto';
            this.component.text.style.pointerEvents = 'auto';
            this.component.textBottom.style.pointerEvents = 'auto';
            this.component.visual.style.display = 'block';
        } else {
            // this.root.classList.remove('fade-in')
            this.component.visual.style.pointerEvents = 'none';
            this.component.text.style.pointerEvents = 'none';
            this.component.textBottom.style.pointerEvents = 'none';
            this.component.visual.style.display = 'none';
        }
    }
    loopHeroArticle(time) {
        const inView = inViewport(this.position)
        const progress = clamp(inView.progress, 0, 1);
        this.component.visual.style.opacity = map(progress, 0.5, 0.75, 1, 0);
        this.component.visual.style.transform = `translate3d(0, 0, 0) scale(${map(progress, 0.5, 0.75, 1, 1.2)})`;
        this.component.text.style.transform = `translate(-50%, -50%) translate3d(0, ${map(progress, 0.5, 0.75, 0, -10)}svh, 0)`;
        this.component.text.style.opacity = map(progress, 0.5, 0.75, 1, 0);
        if(inView.loopback === 1) {
            this.component.visual.style.pointerEvents = 'auto';
            this.component.text.style.pointerEvents = 'auto';
            this.component.visual.style.display = 'block';
        } else {
            this.component.visual.style.pointerEvents = 'none';
            this.component.text.style.pointerEvents = 'none';
            this.component.visual.style.display = 'none';
        }
    }
}

import Swiper, { Navigation, Pagination, Autoplay } from 'swiper';
import 'swiper/swiper.min.css';
import 'swiper/modules/navigation/navigation.min.css';

export default class Gallery {
    init(root) {
        this.root = root;
        this.next = root.querySelector('.swiper-button-next');
        this.prev = root.querySelector('.swiper-button-prev');
        this.swiper = new Swiper(this.root, {
            slidesPerView: 1,
            centeredSlides: true,
            spaceBetween: 30,
            loop: true,
            autoplay: {
                delay: 3000,
                disableOnInteraction: false, // Optional: to prevent autoplay from stopping after interaction
            },
            // Responsive breakpoints
            breakpoints: {
                320: {
                    spaceBetween: 20,
                },
                480: {
                    spaceBetween: 30,
                },
                640: {
                    spaceBetween: 40,
                },
                1200: {
                    spaceBetween: 120,
                },
                1400: {
                    spaceBetween: 240,
                },
            },
            modules: [Navigation, Autoplay],
            navigation: {
                nextEl: this.next,
                prevEl: this.prev,
            },
        });
        this.root.style.opacity = 1;
    }
    
    destroy() {
        this.swiper.destroy();
    }
}

import { loopback, map, clamp } from './../../js/utils/math.js';
import { doc, cursor, scrl, getPosition, inViewport, registeredLoops, touchDevice} from './../../js/utils/events.js';

export default class SwitchDarkLight {
  init(root) {
      this.root = root
      this.body = document.querySelector('body');
      this.toggle = this.root.querySelector('.switchdarklight-toggle');

      if(document.querySelector('body').classList.contains('dark')) {
        this.switchToDark()
      }

      this.render()
      // this.hintAnimation()

      if(this.root.querySelector('.switchdarklight-toggle-nexttoburger')) {
        registeredLoops.push({ //add this.loop to global requestAnimationFrame
            root: this.root,
            component: this,
            position: getPosition(this.root), //trigger element
            loop: this.loop
        })
      }
  }

  render() {
    this.toggle.addEventListener('click', e => {
      if(this.body.classList.contains('light')) {
        this.switchToDark()
      } else {
        this.switchToLight()
      }
    })
  }

  switchToDark() {
    // this.root.classList.remove('switchdarklight--light');
    // this.root.classList.add('switchdarklight--dark');
    this.body.classList.remove('light');
    this.body.classList.add('dark');
  }

  switchToLight() {
    // this.root.classList.remove('switchdarklight--dark');
    // this.root.classList.add('switchdarklight--light');
    this.body.classList.remove('dark');
    this.body.classList.add('light');
  }

  hintAnimation() {
    if(this.body.classList.contains('light')) {
      this.switchToDark()
      setTimeout(() => {
        this.switchToLight()
      }, 1000);
    } else {
      this.switchToLight()
      setTimeout(() => {
        this.switchToDark()
      }, 1000);
    }
  }



  loop(time) {
    if(scrl.scroll > doc.h * 0.5) {
      this.component.toggle.style.opacity = 1;
    } else {
      this.component.toggle.style.opacity = 0;
    }
    // const inView = inViewport(this.position)
    // const progress = clamp(inView.progress, 0, 1);
    // this.component.visual.style.opacity = map(progress, 0.5, 0.75, 1, 0);
    // this.component.visual.style.transform = `translate3d(0, 0, 0) scale(${map(progress, 0.5, 0.75, 1, 1.2)})`;
    // this.component.text.style.transform = `translate(-50%, -50%) translate3d(0, ${map(progress, 0.5, 0.75, 0, -10)}svh, 0)`;
    // this.component.text.style.opacity = map(progress, 0.5, 0.75, 1, 0);
    // if(inView.loopback === 1) {
    //     this.component.visual.style.pointerEvents = 'auto';
    //     this.component.text.style.pointerEvents = 'auto';
    //     this.component.visual.style.display = 'block';
    // } else {
    //     this.component.visual.style.pointerEvents = 'none';
    //     this.component.text.style.pointerEvents = 'none';
    //     this.component.visual.style.display = 'none';
    // }
  }
}

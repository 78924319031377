import '../scss/app.scss';

// Your JS Code goes here
import Hero from '../components/hero/hero.js'
import FeaturedTeaser from '../components/featuredteaser/featuredteaser.js'
import Video from '../components/video/video.js'
import WorkFilter from '../components/work-filter/work-filter.js'
import Gallery from '../components/gallery/gallery.js'
import revealText from '../components/revealtext/revealtext.js'
import LogoGrid from '../components/logogrid/logogrid.js'
import LatestArticles from '../components/latestarticles/latestarticles.js'
import TransparentVideoOverlay from '../components/transparent-video/transparent-video-overlay.js'
// import Clock from '../components/clock/clock.js'
// import Accordion from '../components/accordion/accordion.js';
import Footer from '../components/footer/footer.js';
import SwitchDarkLight from '../components/switchdarklight/switchdarklight.js';
import BackButton from '../components/button/backbutton.js';
// import FounderVideo from '../components/foundervideo/foundervideo.js'
// import Features from '../components/features/features.js'
import CustomCursor from '../components/customcursor/customcursor.js'
import Menu from '../components/menu/menu.js'
import Cases from '../components/cases/cases.js'
import TransitionOverlay from '../components/transition-overlay/transition-overlay.js'
// import Storytelling from '../components/storytelling/storytelling.js'
// import ScrollFadeIn from '../components/scrollfadein/scrollfadein.js'
// import WebGL from '../components/webgl/webgl.js'
import barba from '@barba/core';
// import { gsap } from "gsap";
import { doc, cursor, updatePositions, scrl, siteurl, touchDevice, registeredLoops, getWindowSize} from './utils/events.js';

let galleryInstances = []

let switchdarklight;
initComponents(document);
if(document.querySelector(".switchdarklight-toggle")) {
	switchdarklight.hintAnimation();
}

let menuEl = document.querySelector(".menu");
let menu = new Menu();
menu.init(menuEl)

let customCursor = new CustomCursor();
customCursor.init()

let footerEl = document.querySelector(".footer");
let footer = new Footer();
footer.init(footerEl)

let transitionOverlayEl = document.querySelector(".transition-overlay");
let transitionOverlay = new TransitionOverlay();
transitionOverlay.init(transitionOverlayEl)

function playAutoplayVideos(container) {
	container.querySelectorAll('.video-autoplay, .transparent-video').forEach(video => {
		//console.log(video)
		video.muted = true;
		video.play().catch(error => {
			console.error('Autoplay was prevented:', error);
		});
	});
}


function initComponents(container) {
	//console.log('init Components')

	let switchdarklightEls = container.querySelectorAll(".switchdarklight");
	switchdarklightEls.forEach(switchdarklightEl => {
		switchdarklight = new SwitchDarkLight();
		switchdarklight.init(switchdarklightEl)
	})

	container.querySelectorAll('.gallery').forEach(el => {
		let gallery = new Gallery();
		gallery.init(el)
		galleryInstances.push(gallery)
	})

	
	container.querySelectorAll('.latestarticles').forEach(el => {
		let latestarticles = new LatestArticles();
		latestarticles.init(el)
		galleryInstances.push(latestarticles)
	})

	container.querySelectorAll('.revealtext').forEach(el => {
		let revealtext = new revealText();
		revealtext.init(el)
	})

	container.querySelectorAll('.revealheadline').forEach(el => {
		let revealtext = new revealText();
		revealtext.initHeadline(el)
	})
	
	container.querySelectorAll('.logogrid').forEach(el => {
		let logogrid = new LogoGrid();
		logogrid.init(el)
	})

	// let accordions = container.querySelectorAll(".accordion");
	// accordions.forEach(accordionEl => {
	//   let accordion = new Accordion();
	//   accordion.init(accordionEl)
	// })

	let transparentVideoOverlays = container.querySelectorAll(".transparent-video-overlay");
	transparentVideoOverlays.forEach(videoEl => {
		let video = new TransparentVideoOverlay();
		video.init(videoEl)
	})

	let videos = container.querySelectorAll(".simple-video");
	videos.forEach(videoEl => {
		let video = new Video();
		video.init(videoEl)
	})

	let featuredteasers = container.querySelectorAll(".featuredteaser");
	featuredteasers.forEach(featuredteasersEl => {
		let featuredteaser = new FeaturedTeaser();
		featuredteaser.init(featuredteasersEl)
	})

	let casesEls = container.querySelectorAll(".cases-wrapper, .featuredteaser, .video-embed-lightbox");
	casesEls.forEach(casesEl => {
		let cases = new Cases();
		cases.init(casesEl)
	})

	window.setTimeout(() => {
		playAutoplayVideos(container);
	}, 200);

	if(container.querySelector(".work")) {
		let workFilter = new WorkFilter();
		let workFilterEl = container.querySelector(".work");
		workFilter.init(workFilterEl)
	}
	
	if(container.querySelector(".hero")) {
		let heroEl = container.querySelector(".hero");
		let hero = new Hero();
		hero.init(heroEl)
	}

	let backbuttons = document.querySelectorAll(".backbutton");
	backbuttons.forEach(backbuttonEl => {
		let backbutton = new BackButton();
		backbutton.init(backbuttonEl)
	})
}


// let founderVideos = document.querySelectorAll(".founder-video");
// founderVideos.forEach(founderVideoEl => {
//   let founderVideo = new FounderVideo();
//   founderVideo.init(founderVideoEl)
// })

// let features = document.querySelectorAll(".features");
// features.forEach(featuresEl => {
//   let features = new Features();
//   features.init(featuresEl)
// })


// let storytelling = document.querySelectorAll(".storytelling");
// let storytellingInstances = []
// storytelling.forEach(storytellingEl => {
//   let storytelling = new Storytelling();
//   storytellingInstances.push(storytelling)
//   storytelling.init(storytellingEl)
// })
// console.log(storytellingInstances)

//needs to be run after adding jumplinks
// let mobilemenuEl = document.querySelector(".menu-mobile");
// if(mobilemenuEl) {
//   let mobilemenu = new Mobilemenu();
//   mobilemenu.init(mobilemenuEl)
// }

//fade in highlights
// const fadeinEls = document.querySelectorAll('u, .fade-on-scroll, ol>li, ul, h1, h2, h3, p, figure, .button .primary, .storytelling-counter')
// console.log(fadeinEls)
// fadeinEls.forEach(el => {
//   let fadein = new ScrollFadeIn();
//   fadein.init(el)
// })


// let webglCanvas = document.querySelector(".webgl");
// let webgl = new WebGL();
// webgl.init(webglCanvas)


// import Experience from './Experience/Experience.js'
// if(document.querySelector('.webgl')) { //to init add experience.php in header.php
// 	const experience = new Experience(document.querySelector('canvas.webgl'))
// }


history.scrollRestoration = "manual";
var scrollData = []; // Array to store scroll position and namespace

barba.init({
	preventRunning: true,
	debug: false,
	// logLevel: 'debug',
	transitions: [
		{
			name: 'opacity-transition',
			leave(data) {
				return transitionOverlay.show();
			},
			enter(data) {
				menu.closeInstant();
				data.current.container.style.display = "none";
				window.scrollTo(0, 0);
				data.next.container.style.display = "block";
				playAutoplayVideos(data.next.container);
				return transitionOverlay.hide();
			}
		}
	]
});

barba.hooks.afterLeave((data) => {
	galleryInstances.forEach(inst => {
		inst.destroy();
	});
});

barba.hooks.enter((data) => {
	if (data.trigger !== "back") {
		scrollData.push({
			namespace: data.current.namespace,
			scrollY: barba.history.current.scroll.y
		});
	}
});

barba.hooks.after((data) => {

	let currentPage = data.next.namespace;
	//console.log(`enter page: ${currentPage}`);

	//update matomo
	if (typeof _paq !== 'undefined') {
		//_paq.push(['trackEvent', 'Navigation', 'Page load', data.next.namespace]);

		_paq.push(['setCustomUrl', window.location.pathname]);
		_paq.push(['setDocumentTitle', document.title]);
		_paq.push(['trackPageView']);
	}

	//look for matomo optout
	if(data.next.container.querySelector('#matomo-opt-out')) {
		const script = document.createElement('script');
		script.src = "https://stan-studios.com/matomo/index.php?module=CoreAdminHome&action=optOutJS&divId=matomo-opt-out&language=en&showIntro=1";
		script.addEventListener('load', () => {
			setTimeout(() => {
				optOutInit();
			}, 3000);
		});
		document.head.appendChild(script);
	}


	registeredLoops.length = 0; // Reset loops
	
	initComponents(data.next.container);
	menu.updateActiveState(currentPage);

	if (data.trigger !== "back") {
		window.scrollTo(0, 0);
	} else {
		// Get the last stored scroll position
		const lastScrollData = scrollData.pop();
		if (lastScrollData && lastScrollData.namespace === currentPage) {
			window.scrollTo(0, lastScrollData.scrollY);
		} else {
			window.scrollTo(0, 0);
		}
	}
});

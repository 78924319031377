export default class Menu {
  init(root) {
      this.root = root
      this.toggle = document.querySelector('.burger');
      this.toggleIcon = document.querySelector('.burger-icon');
      this.menuItems = this.root.querySelectorAll('.menu-item')
      this.body = document.querySelector('body');
      // console.log(this.root)
      this.render()
  }

  render() {
    this.toggle.addEventListener('click', e => {
      this.toggleIcon.classList.toggle('is-active');
      this.root.classList.toggle('is-active');
      this.body.classList.toggle('menu-visible')
    })
  }

  updateActiveState(currentPage) {
    //console.log('currentPage')
    //console.log(currentPage)
    this.menuItems.forEach(item => {
      if (item.classList.contains(`menu-item-${currentPage}`)) {
        item.classList.add('active');
      } else {
        item.classList.remove('active');
      }
    });
  }
    
  close() {
    this.toggleIcon.classList.remove('is-active');
    this.root.classList.remove('is-active');
    this.body.classList.remove('menu-visible')
  }
  closeInstant() {
    this.root.style.display = "none";
    this.close();
    window.setTimeout(() => {
      this.root.style.display = "block";
    }, 1000);
  }
}
